import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesFlora: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Flora guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_flora.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Flora Guide & Review</h1>
          <h2>A guide & review for Flora in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>23/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Flora" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="flora" enablePopover />
              </div>
              <p>
                To tend to a garden is to nurture one's mind. Meet Flora, the
                Ark cutest horticulturist and anthophile, a support B2 character
                who can continually heal the team as well as protect them with
                individual shields. The magic of tranquility also allows Flora
                to refresh the buff stacks of Electric allies and buff the
                team's True Damage.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Flora is cute, but in battle, her cuteness clashes with what is
                needed most: aggression. She is not a strong unit but also not a
                weak unit. She is a survivalist, a situational healer that can
                be put in team whenever the relevant situation arises. She is
                similar to Rapunzel, BunnyAlice, or SMary in nature, in the way
                that they are not ubiquitous (at least in PvE context) and that
                she can be used <strong>off-burst</strong>. However, this means
                she is not a meta unit at all. This might change once we get
                overpowered pure True Damage B3, but for now that is not the
                case.
              </p>
              <p>
                That being said, Flora's continuous healing is a unique quality
                that other healers do not have. Not to mention, she can also
                generate shields for the team should adjacent allies drop in HP
                below a certain threshold, which can be forced by non-restoring
                Max HP buffs. This creative gimmick has intrigued us into
                exploring some interesting synergies with her, and while we did
                find plenty, none of them was really game-breaking. This also
                applies to Flora's ability to increase the stack count of buffs
                of all Electric units in the team. Nevertheless,{' '}
                <strong>Grave, PrivM (+/- Tove), and Exia(★)</strong> showed the
                most promising interactions.
              </p>
              <p>
                In terms of offense, Flora's offensive support is limited to
                True Damage. She has two skills that can buff the team's True
                Damage. However, activating them can be cumbersome as they
                require intentional HP loss, waiting for 40s cooldown, or the
                application of MAX HP buffs. While this works in conjunction
                with the continuous heal we mentioned earlier, it is not
                consistent.
              </p>
              <p>
                Last but not least, PvP — she can probably fit in some stall
                comps, especially alongside units whose HP can continually dip
                below 90% and rise back up (such as Noise/Rapunzel). She can
                buff the team's True Damage too, so Ein's or Laplace's attacks
                can hurt more, but nothing too surprising. Her weakness is the
                usual same as other MG units: low burst generation and
                counterable by Jackal/Scarlet. Do note that her continuous heal
                does not spam-trigger Nero's ability.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                The answer is <strong>NO</strong>, unless you like her design.
                Currently, she is not meta enough to warrant pulling (might
                change in the future). Anniv has just squeezed most of us dry,
                and soon we will be faced with limited Christmas units (perhaps
                with reruns of old units) as well as a likely broken unit on New
                Year. Unless you have overflowing gems, saving is your best bet.
              </p>
              <p>
                If you do like her cute design, however, the writer thinks she
                is the cutest Nikke that ever exists (very subjective). If you
                think the same way, then you can pull her.
              </p>
              <p>
                <i>
                  We are not to be held responsible for any bad decisions caused
                  by you going down the waifu path.
                </i>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/flora_guide_1.webp"
                alt="Kit"
              />
              <h5>Petunia</h5>
              <blockquote>
                <p>
                  ■ Activates at the start of battle only if self survives.
                  Affects self and 2 allies on both sides.
                </p>
                <p>
                  Recovers 1% of caster's final Max HP every Sec continuously.
                </p>
                <p>HP restored ▲ 4% continuously, stacks up to 5 time(s).</p>
                <p>
                  ■ Activates after landing 100 normal attack(s). Effects all
                  Electric Code allies.
                </p>
                <p>Stack count of buffs ▲ 1.</p>
              </blockquote>
              <p>
                Flora continuously heals units beside her in proportion to her
                Max HP. This is a buff tied to Flora that is active as long as
                Flora is alive and is not a skill. The heals are considered one
                heal instance, so they do not trigger Crown's nor Nero's passive
                over and over. Since it is a buff, it is also removable by the
                likes of Rosanna in the same way as she removes Jackal's Damage
                Share.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Positioning
                  is Key
                </h6>
                <p>
                  The fact that Flora's continuous healing is not teamwide makes
                  unit positioning very important. It is also a big letdown
                  since most PvE content outside Solo Raid has no predictable
                  targeting.
                </p>
              </blockquote>
              <StaticImage
                src="../../../images/nikke/reviews/flora_guide_2.webp"
                alt="Kit"
              />
              <p>
                <i>
                  Note that Nero is technically not right beside her so she
                  doesn’t have the heal although nothing is in P4.
                </i>
              </p>
              <p>
                She also grants HP restoration buff to them, which can stack up
                to 5 times, but unless there are stack refreshers in the team,
                it will be stuck in 1 stack. Thankfully, the second part of her
                Skill 1, which allows her to increase the buff stack count of
                all Electric allies, fixes this. The problem is it only affects
                Electric units, so not much hope for other elements (not like
                the effect is game-changing in the first place).
              </p>
              <p>
                Her stack refresh allows for some interesting interactions with
                certain other Electric units, some meaningful ones listed below:
              </p>
              <ul>
                <li>
                  {' '}
                  <NikkeCharacter
                    mode="inline"
                    slug="rosanna"
                    enablePopover
                  />{' '}
                  - Rosanna gains ATK buff every time an ally or an opposing
                  Nikke dies. After ATK buff is gained, Flora can stack this up,
                  giving her up to 220% ATK buff just by existing. However, both
                  being 40s makes it impossible to optimally run in PvE. Unless
                  you are recording for content, for example “Flora + Rosanna
                  FREE Alteisen Clear”, then there is no point in pairing them
                  together.
                </li>
                <li>
                  {' '}
                  <NikkeCharacter
                    mode="inline"
                    slug="privaty-unkind-maid"
                    enablePopover
                  />{' '}
                  - Privaty: Unkind Maid possesses a stackable buff that she
                  cannot stack on her own and needs assistance from Tove and/or
                  Leona. With Flora (assuming she has enough Max Ammo to keep
                  shooting consistently), then PrivM can technically keep her S2
                  ATK buff stacks forever even without their help. Not only
                  that, Tove's Burst ATK buffs are also independent on each
                  unit, which means they can be refreshed and extended by stack
                  refreshers, but their max stack is still capped by Tove's S1
                  real-time stacks.
                </li>
                <li>
                  {' '}
                  <NikkeCharacter
                    mode="inline"
                    slug="exia"
                    enablePopover
                    showTreasure
                  />{' '}
                  - Flora can help Exia(★) stack her Hacking Code faster and
                  more effortlessly (no longer need to Full-Charge to maintain
                  stacks) and also speed up the acquisition of her supportive
                  ATK buffs on teammates.
                </li>
                <li>
                  {' '}
                  <NikkeCharacter
                    mode="inline"
                    slug="cinderella"
                    enablePopover
                  />{' '}
                  - Allows her to stack Beauty faster, increasing her damage.
                  Cinderella also helps trigger Flora's S2 True Damage Buff for
                  the first few seconds of the game (more on that later).
                </li>
                <li>
                  {' '}
                  <NikkeCharacter mode="inline" slug="harran" enablePopover /> -
                  Once Harran gets a kill, Flora can indefinitely sustain her
                  ATK buff.
                </li>
              </ul>
              <h5>Iris</h5>
              <blockquote>
                <p>
                  ■ Activates when the HP of one of the allies on both sides is
                  lower than 90%. Affects all allies.
                </p>
                <p>
                  Generates a Shield with 10.22% of the caster's final Max HP
                  for 10 sec.
                </p>
                <p>
                  ■ Activates when the HP of one of the allies on both sides
                  reaches Max HP. Affects all allies.
                </p>
                <p>True Damage▲ 30.97% for 5 sec.</p>
              </blockquote>
              <p>
                This skill has two components: Shield and True Damage buff.
                Let's talk about Shield first.
              </p>
              <ul>
                <li>
                  Shield: Whenever an ally adjacent to Flora drops below 90% in
                  HP, Flora erects Individual Shields for all allies. Individual
                  Shields mean they are not shared and therefore can also
                  trigger Naga's and Asuka's passives. The shield HP is low, but
                  it can be useful for blocking single high-damaging attacks,
                  and since it can be spammed, it might make for some ridiculous
                  shield comps. To reactivate the shield, the units' HP must be
                  restored to above 90% (and they have to drop in HP again).
                </li>
              </ul>
              <Alert variant="primary">
                <p>
                  JScarlet, Guillotine, A2, and Grave*, as well as units with
                  non-restoring Max HP buffs can trigger this buff repetitively.
                  Grave* needs to be positioned away from Flora, so her Burst
                  Skill would drop her below 90%
                </p>
              </Alert>
              <ul>
                <li>
                  True Damage: Whenever an ally next to Flora reaches Max HP,
                  Flora temporarily converts the excess energy and grants all
                  teammates True Damage buff for 5 seconds. The True Damage buff
                  is quite big, but the activation requirement is a hassle. Why
                  is that? Well, if your units never drop in HP, then this buff
                  would never trigger as they get stuck in Max HP. Yes, her
                  continuous healing does not make everyone get True Damage
                  permanently if they are always at Max HP.
                </li>
              </ul>
              <p>
                However, there are two other ways of triggering this buff too —
                Max HP without restoring HP, and Max HP with HP restoration!
                Non-restoring Max HP will temporarily reduce the percentage of a
                unit's HP before returning it back to 100% (as long as they are
                not wounded), triggering the buff as soon as it expires.
                Meanwhile, restoring Max HP buffs <strong>immediately</strong>{' '}
                triggers this buff if the corresponding unit is at 100% HP.
                However, the MAX HP must expire first before you trigger it
                again, otherwise True Damage won't be refreshed. Noise and Frima
                are good examples who can do this.
              </p>
              <Alert variant="primary">
                <p>
                  Units like Scarlet, Guillotine, and Grave* can intentionally
                  lose HP and be healed back to full to gain the whole team True
                  Damage. Grave* must be positioned next to Flora and have lower
                  base HP than her (with some small tolerance if higher), so
                  that Flora's continuous heal per second can outvalue Grave's
                  1% HP loss per second during Burst Skill, effectively making
                  S2 True Damage permanent.
                </p>
              </Alert>
              <h5>Secret Garden</h5>
              <blockquote>
                <p>■ Affects all allies.</p>
                <p>Recovers 10.45% of caster's final Max HP as HP.</p>
                <p>True Damage ▲ 42.39% for 10 sec.</p>
              </blockquote>
              <p>
                This skill is quite useless because it has a 40s CD, but the
                True Damage buff might be worth it if you line it up with the
                Burst Skill of a True Damage dealer. However, it is still weak,
                and Flora is better run as an off-burst unless you know that
                using her Burst Skill will lead to more damage. If the other B2
                simply has a stronger Burst Skill effect, then totally avoid
                using hers. The heal is minor too, but we guess it can save
                lives? By the way, it is the only skill that allows her to heal
                the team.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Burst CD
                </h6>
                <p>
                  Her being 40s forces her to be run alongside another B2. This
                  may or may not be a problem, and it's <strong>NOT</strong>{' '}
                  always a bad thing (i.e. Biscuit was meta in Solo Raid
                  before). In fact, we believe she works better as an off-burst
                  unit like Naga, Rapunzel, BunnyAlice, or SMary. However, the
                  problem is: if there are better units to be added, why her?
                  Any specific reason why she is better than the other options?
                </p>
              </blockquote>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="c">C</strong> | Campaign (HD)
                - <strong className="d">D</strong>
              </h5>
              <p>
                Likely used as an off-burst healer/shielder (primarily with True
                Damage DPS) or for instant second rotation strat (with Red Hood,
                same as with Naga) — for example, RH → Crown → B3, then RH →
                Flora → Other B3. Nothing too special, but she can help the team
                stay alive like Rapunzel and Bunny Alice does.
              </p>
              <p>
                What makes her better is that her shield can block high-damaging
                non-piercing attacks that would otherwise be lethal. The shield
                can also allow Naga to activate her core damage buff, which is a
                very good thing if you don't have access to Crown or Tia.
              </p>
              <p>
                Problem is, can you safely trigger it against enemies who
                oneshot your comps? Do you want to RNG the enemies hitting the
                Nikke(s) next to her too? She can't also spam-trigger Crown's
                passive, while these other healers can.
              </p>
              <p>
                To conclude, limited usage, probably handy for beginners, but
                will be dumped immediately upon getting a better
                survival-focused B2 unit.
              </p>
              <h5>
                Bossing - <strong className="b">B</strong>
              </h5>
              <p>
                Likely used as an off-burst healer/shielder (primarily with True
                Damage DPS), or as a complement for another 40s B2 (such as
                Biscuit). However, her offensive impact is still overshadowed by
                Naga and in some cases Bunny Alice thanks to her Max Ammo▲ and
                Instant Reload.
              </p>
              <p>
                Flora’s synergy with certain units such as PrivM (which has been
                a key unit in Solo Raids for a while) may make her a better
                choice in some situations, but it is unlikely that it will be
                meta. You can still use it if you cannot afford to make the
                better teams.
              </p>
              <h5>
                PVP - <strong className="d">D</strong>
              </h5>
              <p>
                Very limited usage as a True Damage buffer or as a sustainer in
                stall comps. Pairing her with Noise, Rapunzel, or Emma may make
                for some interesting shield-spam comps, but she does not really
                break the meta. Her True Damage buff, on the other hand, is
                quite big if we can trigger her S2 and Burst Skill, but that
                would require some ingenious placements. Can also help stack
                Rosanna's or Scarlet's ATK buff, but be careful as her fire rate
                is limited (30/s). Counterable by Scarlet and Jackal and has low
                burst gen. Also be reminded that her continuous heal only counts
                as one heal instance for Nero.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Upprading and leveling up Flora's Vest, Helmet, and Boots
                increase her base HP and allow her to heal the team more
                efficiently. Giving her Max Ammo OL will ensure she can trigger
                her stack refresh much more consistently (if that is not needed
                in your comp, you do not need it). If you want her to deal some
                extra chip damage, aim for additional ELE & ATK lines.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Max Ammo, 4× ELE, 4× ATK
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Flora's skills need not be upgraded as they are quite good
                enough at base level.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 1~4</strong>
                </li>
                <ul>
                  <li>
                    Upgrade for increased HP restoration buff but mainly for CP
                    padding.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 1~10</strong>
                </li>
                <ul>
                  <li>
                    Upgrade only for True Damage purposes. If needed, we
                    recommend leaving it at 4 and most 7, but 10 is the limit.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 1~10</strong>
                </li>
                <ul>
                  <li>
                    Flora is more likely to be run as an off-burst, but in some
                    cases, her Burst Skill might be more desirable. If needed,
                    we recommend leaving it at 4 and at most 7, but 10 is the
                    limit.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="flora" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                In Reload Speed comp, use Resilience. Otherwise, use Bastion.
                You can use Vigor and Healing Cube(s) to boost her healing.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The True Damage Squad</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="frima" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="clay" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                It lacks CDR, yes. It also fixes your rotation to max 9 Full
                burst. But it provides a lot of true damage buff to Laplace and
                Ein. For your record, this team gives 141.01% /170.84% true
                damage buff during Clay/Flora’s burst.
              </p>
              <h5>Team #2: Permanent S2 True Damage</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                This team abuses Grave's HP loss in Burst Skill and Flora's
                continuous heal to keep triggering Flora's S2 True Damage buff.
                Any B1 CDR that does not have unit dependency can be used, like
                Volume but not Dorothy. Use Grave's Burst Skill only.
              </p>
              <h5>Team #3: Auto ExiaTr Finally Possible?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="exia"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                {' '}
                Exia(★) normally needs manual gameplay to work, but Flora allows
                her to be run auto and allows her to stack personal and team
                buffs faster as well. Flora can also keep the team healthy.
                Flora's Burst Skill can be used here, in conjunction with your
                True Damage B3, to provide more damage as the other B2 CDR Burst
                Skills are rather weak.
              </p>
              <h5>Team #4: PrivM Permanent Buffs</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="tove" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="leona" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="soda-twinkling-bunny"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                <i>You can use any other B2/B3 in place of Leona/B.Soda</i>
              </p>
              <p>
                This team utilizes Flora's stack refresh to maintain PrivM's
                buffs permanently, including the one she has herself and the one
                that Tove has (from her Burst Skill, which gives independent ATK
                buff to teammates). Flora needs to have Max Ammo for this team
                to be effective as she needs to shoot to trigger the stack
                refresh, but the problem is that investing in this comp is just
                a waste of resources because it is rather pointless (still
                weaker than other variants too).
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: This Seems to Exist in Every Guide</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is just an example of what you can do with Flora as a
                B2 buffer. Noise's Max HP will instantly trigger Flora's S2. Do
                note that if you use Anis for faster Full Burst, put Flora to
                her left, so Flora's Burst gets used instead.
              </p>
              <h5>Team #2: Stall Comp?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="quiry" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team just heals a lot and stalls, withstanding as much
                attack as possible and winning through sheer sustaining. Highly
                counterable.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Continuous healing is a unique quality.</li>
                        <li>
                          Can grant substantial True Damage buff as an off-burst
                          unit.
                        </li>
                        <li>
                          Burst Skill grants considerable True Damage buff
                          despite having a 40s CD.
                        </li>
                        <li>
                          Can spam shields for the team (as long as triggers are
                          fulfilled).
                        </li>
                        <li>Increases team's HP recovery.</li>
                        <li>
                          Can refresh the stacks of Electric units, boosting QoL
                          and allowing for some creative synergies.
                        </li>
                        <li>
                          The most adorable Nikke according to Kisenix (highly
                          subjective). Worth pulling just because of that (also
                          highly subjective). Cute Burst Skill animation.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>40s Burst CD makes her very limiting.</li>
                        <li>
                          Her heal is only for two adjacent allies, which is
                          okay for Solo Raid where targeting is more
                          predictable. However, for everything else, it may be
                          inconsistent. Also, Burst Skill's heal is pretty much
                          negligible.
                        </li>
                        <li>Shield's activation may be inconsistent.</li>
                        <li>
                          S2 True Damage does not trigger unless there are Max
                          HP skills in the team and/or adjacent allies get
                          damaged.
                        </li>
                        <li>
                          To stack refresh properly, you need to invest in her
                          OL and get Max Ammo, which can be considered a waste
                          of resources for non-meta units like her.
                        </li>
                        <li>Going down the waifu path costs money.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesFlora;

export const Head: React.FC = () => (
  <Seo
    title="Flora Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Flora in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
